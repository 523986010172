html {
  scroll-behavior: smooth;
  overflow-y: scroll;
}

body {
  font-family: 'Recia', serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Erode', serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

div {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

div::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}